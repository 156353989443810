import React from "react";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";

// Twin App Header
import AppHeader from "../components/AppHeader/AppHeader";

const Terms = props => {
  const { appHeader, twinApp } = queryString.parse(props.location.search);

  return (
    <div>
      <Seo
        title="Diabetes Reversal Program"
        description="Start your diabetes reversal journey. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
      />

      {appHeader === "mobile" && twinApp === "yes" && (
        <AppHeader text="TERMS" isBack={true} />
      )}

      <div className="p-3">
        <div className="text-center twinPrimaryAppFont">
          <h1>TWIN TERMS OF USE</h1>
        </div>
        <div className="text-left mt-4 twinPrimaryAppFont">
          <h3>Introduction</h3>
          <h5>Index</h5>
          <p>1.Medical Policy</p>
          <p>2.Twin Service Policy</p> <p>3.Privacy Policy</p>
          <p>4.Terms of use</p>
          <p>5.Annexures</p>
          <h3 className="text-center mt-3">1.Medical Consent Form</h3>
          <p className="mt-3">
            I, the undersigned, do hereby state and confirm as follows:
          </p>
          <p>
            1. The following has been explained to me in terms and language that
            I understand, in __________________________________ (Insert name of
            your language or dialect).
          </p>
          <p>
            2. I have been provided with the necessary information, which I
            understand; and based on this understanding I consent, authorize and
            direct the Provider identified above and his/her associated doctors
            and their team(s) of associates and assistants of each doctor’s
            choice to provide me with remote telehealth services, including use
            of the Twins Digital service offered by Twins Digital Services India
            Private Limited and the terms of use for such service as provided to
            me, which are incorporated by reference into this consent.
          </p>
          <p>
            3. It has been explained to me and I understand that due to
            unforeseen circumstances during the course of the proposed
            treatment/intervention something additional or different than what
            has been originally planned and for which I am giving this consent
            may have to be performed or attempted. In all such eventualities, I
            authorize and give my consent to the medical team(s) to perform such
            other and further acts that they may deem fit and proper using their
            professional judgement.
          </p>
          <p>
            4. Alternative methods and therapies to the proposed treatment /
            intervention, their respective benefits, material risks and
            disadvantages (if any), have been explained to me and I understand
            them.
          </p>
          <p>
            5. I have received explanations and understand that the proposed
            telehealth treatment has certain material risks/complications and I
            have been provided with their quite information about the same,
            including that there are other undefined, unanticipated, unexplained
            able risks/complications that may occur during or after the proposed
            treatment /intervention.
          </p>
          <p>
            6. I confirm that the Provider has answered all my questions to my
            satisfaction regarding the proposed treatment / intervention and the
            medical teams involved.
          </p>
          <p>
            7. I understand after full explanation and the opportunity for
            discussion that despite best efforts there can be no assurance about
            the results or outcome of the proposed treatment/intervention. I
            confirm that I have not been given any guarantee or warranty about
            the results or outcome of the proposed treatment /intervention.
          </p>
          <p>
            8. After explanation and opportunity for discussion, I understand
            that despite all precautions, complications may occur that may
            require additional treatment / intervention, disability or prolonged
            discomfort.
          </p>
          <p>
            9. I have been advised of my own election to seek and obtain a
            second opinion from another doctor regarding the proposed treatment
            /intervention.
          </p>
          <p>
            10. I confirm that after explanation, discussion, counselling and
            disclosures I have had ample time to provide consent.
          </p>
          <p>
            11.I understand that I have the right to refuse treatment or to
            withdraw my consent at any time. I understand that if I do refuse
            treatment, the consequences of my decision have been explained to me
          </p>
          <p>
            12. I have signed this consent voluntarily out of my free will and
            without any kind of pressure or coercion.{" "}
          </p>
          <h3 className="text-center mt-3">2.Twin Service Policy</h3>
          <h5>2.1 Sensors:</h5>
          <p>
            1. I understand that I will have to procure all the necessary
            sensors that is required to form my Digital Twin
          </p>
          <p>
            2. I understand that the basic sensors I will have to procure is
            Continuous Monitoring Glucose Patches (CGM) & Reader, Activity
            Tracker & Body Composition Scale, BP Monitor (If I take BP
            medications) & Ketone Meter (if I take Insulin to manage my
            Diabetes)
          </p>
          <p>
            I agree to send all my CGM readings to remote@twinhealth.com on a
            daily basis. I understand that I will have to procure 6 CGM patch in
            a year. In exception scenarios, where if Remission is not achieved,
            I will have to purchase extra CGM patches as per my Digital Twin
            requirement.
          </p>
          <h5>2.2 Lab/Blood Work:</h5>
          <p>
            1.I understand that as part of my treatment, I will be taking blood
            diagnostics done 4 times in addition to the one done in a few days
            from the day of enrollment.
          </p>
          <p>
            2.Each of these blood tests will be done quarterly once, on day 1,
            day 90, day 180, day 270 and day 360 respectively.
          </p>
          <p>
            3.The list of blood tests which will be done - Refer to Annexure A
            for the blood tests parameters{" "}
          </p>
          <p>
            4. I understand that I will share all blood draw reports with Twin{" "}
          </p>
          <h5>Twin Virtual Service Assistant (TSA):</h5>
          <p>
            1.I understand that if I have any issues with my sensors, my sensor
            team will support me virtually.
          </p>
          <p className="mt-3">
            <bold>
              DISCLAIMER: I understand this program is a No Refund Policy and I
              agree that I will fully comply to the program guidelines and
              instructions for my journey
            </bold>
          </p>
          <h3 className="text-center mt-3">3. Privacy Policy</h3>
          <p>Effective: July 07, 2023</p>
          <p>Last modified: July 07, 2023 </p>
          <p>
            Twin Health, Inc. and its related companies (“We”, “Us”, “Twin
            Health”) take your privacy seriously, and we want you to know how we
            collect, use, share, and protect your information. This policy (the
            “Privacy Policy”) tells you what information we collect from
            visitors of the mobile application and therapy or other related
            services provided or operated by Twin Health (the “Services”)
            including our mobile application (the “App”), how we use that
            information, how we may share that information, how we protect your
            information, and your choices regarding your Personal Information
            (defined below). This Privacy Policy does not apply to information
            collected by us through any other means, including on any other
            website operated by Twin Health or any third party; or any third
            party, including through any application or content (including
            advertising) that may link to or be accessible from or on the App.
          </p>
          <h5>
            NOTE, TWIN HEALTH MAINTAINS THE PRIVACY OF YOUR HEALTH INFORMATION
            IN ACCORDANCE WITH TWIN HEALTH’S NOTICE OF PRIVACY PRACTICES,
            AVAILABLE AT ON OUR WEBSITE. IN THE EVENT OF A CONFLICT BETWEEN THIS
            PRIVACY POLICY AND THE NOTICE OF PRIVACY PRACTICES, THE NOTICE OF
            PRIVACY PRACTICES SHALL PREVAIL.
          </h5>
          <p>
            PLEASE READ THIS POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND
            PRACTICES REGARDING YOUR INFORMATION AND HOW WE WILL TREAT IT. IF
            YOU DO NOT AGREE TO THE PRIVACY POLICY, INCLUDING ANY CHANGES
            THERETO, THEN YOU MUST NOT ACCESS AND USE THE SERVICES. THIS PRIVACY
            POLICY MAY CHANGE FROM TIME TO TIME (SEE CHANGES) AND YOUR CONTINUED
            USE OF THE APP AFTER WE MAKE CHANGES IS DEEMED TO BE ACCEPTANCE OF
            THOSE CHANGES, SO PLEASE CHECK THIS POLICY PERIODICALLY FOR UPDATES.
          </p>
          <p>
            To utilize the Services, you are required to submit certain
            information to establish an account. We collect certain information
            during the account registration process for identity verification
            purposes. We collect and utilize additional information as set forth
            below.
          </p>
          <h5>Information We Collect From You </h5>
          <p>
            We collect several types of information from and about users of our
            App (collectively “Personal Information”), including, specifically:
            Personally identifiable information about you, including, when you
            register with the Services, your name, home, work and shipping
            addresses, telephone number, email address, contact information and
            password, and, once you have registered, your birthdate, racial or
            ethnic origin, health information, health insurance information,
            your medical history, including any diagnoses and how long you have
            been having symptoms, and any other information you provide to us,
            or grant us access to, when you access or use the Services;
          </p>
          <p>
            Information that you provide by filling in forms on our App,
            including at the time of registering to use the Services, using the
            Services, or requesting further Services. We may also ask you for
            information when you report a problem with our App; Records and
            copies of your correspondence (including email addresses), if you
            contact us; and Details of Services requested through our App and
            the fulfillment of your requests. We will process Personal
            Information collected through the Services in accordance with
            applicable law and with the agreements we have with your employer,
            and as described in the Terms of Use and this Privacy Policy. If you
            do not want to provide us this information, please do not utilize
            the Services.
          </p>
          <h5>Information We Collect From Third Parties</h5>
          <p>
            We may collect Personal Information about you from third parties,
            such as your employer, your health plan, and our business partners
            and service providers. Your employer will provide us with
            information so that we can provide our Services to you.
          </p>
          <h5>Information We Collect Automatically</h5>
          <p>
            The information we collect automatically may include Personal
            Information or we may maintain it or associate it with Personal
            Information we collect in other ways or receive from third parties.
            It helps us to improve our App and to deliver a better and more
            personalized service. Additionally, we may collect certain
            information automatically when you use the Services, including the
            following:
          </p>
          <p>
            Use of the App. When you use the App, we may collect certain
            information about such use, including traffic data, logs, the
            location of your mobile device, your mobile device ID, type of
            device and operating system you use to access our App; your browser
            type; your mobile network information; your activities within the
            App; the length of time that you are logged in through the App; and
            information stored on your mobile device, including photographs,
            audio and video clips, personal contacts, and address book
            information.
          </p>
          <p>
            Cookies. We may use “cookies” and other technologies to collect data
            that enable us to better understand and improve the usability,
            performance and effectiveness of our Services. For instance, we may
            use session cookies (which expire once you close your browser) to
            provide you with a more personal and interactive experience on our
            App. A “cookie” is a small piece of data sent from a website and
            stored on the user's computer by the user's web browser while the
            user is browsing. You may have the capability on your mobile device
            to refuse to accept browser cookies by activating the appropriate
            setting in the preferences for your operating system or browser.
            However, if you select this setting you may be unable to access
            certain parts of our App. Unless you have adjusted your browser or
            operating system setting so that it will refuse cookies, our system
            will issue cookies when you access our App.
          </p>
          <p>
            Third-Party Analytics. We use third parties’ analytic and tracking
            tools to better understand who is using the Services, how people are
            using the Services and how to improve the effectiveness of the
            Services and its content, and to help those parties serve more
            targeted advertising to you across the Internet or help us serve
            more targeted advertising to you. Those third party companies may
            use cookies, pixel tags or other technologies to collect and store
            anonymous information such as time of visit, pages visited, time
            spent using the Services, device identifiers, type of operating
            system used and other website(s) you may have visited.
          </p>
          <p>
            They might combine information they collect from your interaction
            with the Services with Personal Information they collect from other
            sources. We do not have access to, nor control over, third parties’
            use of cookies or other tracking technologies. We currently use the
            analytic and tracking tool Google Analytics, a web analytics service
            provided by Google, Inc. (“Google”), and Mixpanel, a data analytics
            platform provided by Mixpanel, Inc. (“Mixpanel”). You can find out
            more about how Google uses data when you visit our App by visiting
            “How Google uses data when you use our partners' sites or apps”,
            (located at www.google.com/policies/privacy/partners/). We also
            encourage you to review Mixpanel’s privacy policy, which can be
            found at https://mixpanel.com/legal/privacy-policy/, to learn more
            about how Mixpanel uses and protects your information.
          </p>
          <p>
            DNT. Do Not Track (DNT) is a privacy preference that users can set
            in some web browsers, allowing users to opt out of tracking by
            websites and online services. Twin Health and the Services do not
            currently recognize or respond to DNT.
          </p>
          <h5>What We Do with Your Information. </h5>
          <p>
            Twin Health respects your privacy and will not sell your Personal
            Information to third parties; however, we may disclose your Personal
            Information for purposes as outlined in this Privacy Policy. We may
            use your Personal Information to: provide Services to you, respond
            to your inquiries, provide information on products and Services you
            request or have a representative contact you regarding our products
            or Services that you may be interested in, provide you with notices
            about your account, carry out our obligations and enforce our rights
            arising from any contracts entered into between you and us or your
            employer and us, including for billing and collection, notify you
            about changes to our Services, our App, or any products or Services
            offered through them, fulfill any other purpose for which you
            provide it, and for any other purpose with your consent. Unless you
            have otherwise opted out of receiving email or text communication
            from us, you agree by using the Services, to allow us to use your
            email or telephone number for communication and provision of
            Services consistent with this Privacy Policy. Please note that if
            your access to our App or use of the Services is sponsored by a
            third party (e.g. your employer or health plan), at the request of
            the sponsoring third party, we may share Personal Information that
            your provide to us, or that we otherwise collect, through the App or
            the Services with your employer and/or third party service providers
            of your employer. Some information Twin Health collects constitutes
            protected health information (“PHI”) under the U.S. Health Insurance
            Portability and Accountability Act (“HIPAA”). As set forth above,
            Twin Health will provide you with a Notice of Privacy Practices
            describing its collection, use, and disclosure of your health
            information. Twin Health will use and disclose PHI only as permitted
            in accordance with our Notice of Privacy Practices and we only
            collect the PHI we need to fully perform our Services and to respond
            to you. We may use your PHI to contact you to the extent permitted
            by law, to provide requested services, to provide information to
            your insurers, to obtain payment for our services, to respond to
            your inquiries and requests, and to respond to inquiries and
            requests from your insurers (and as provided below your employers)
            and benefits program. We may combine your information with other
            information about you that is available to us, including information
            from other sources, such as from your insurers or benefits program,
            in order to maintain an accurate record of our participants. PHI
            will not be used for any other purpose, including marketing, without
            your consent. We may also use your Personal Information to improve
            our products and Services, provide information regarding health
            topics, deliver other information we believe you will find most
            relevant, and useful and in any other way we may describe when you
            provide the information or to which you consent. We may occasionally
            contact you to gather customer service information to help us
            determine how we can improve our Services and products to better
            meet your needs. We may also de-identify and/or aggregate your data
            so that it no longer reasonably identifies you for various business
            purposes including product, service and program development and
            improvement. De-identified data, in individual or aggregated form,
            may be used without restriction and for any purpose, including for
            research purposes both internally by Twin Health and with research
            partners and other third parties for the advancement of clinical and
            scientific knowledge. We may combine or cross-reference your
            Personal Information with general information or other information
            we may have acquired about you or may acquire about you through
            other sources, including offline sources of information to help
            further customize the information, products or Services we provide
            to you. We use the general information we collect from you to help
            us understand and analyze users of our Services, including
            generating aggregate statistics about Services use. This data can
            then be used to tailor the Services’ content and deliver a better
            experience for our users. We may also collect, aggregate and
            maintain anonymous information about the visitors to our Services.
            We may further share such aggregate, non-identifiable information
            with business partners, sponsors and other third parties.
          </p>
          <h5>Sharing of Personal Information with Third Parties.</h5>
          <p>
            We may disclose Personal Information that we collect or you provide
            as described in this Privacy Policy to your employer in connection
            with your employer’s employee health monitoring. From time to time,
            we may use third parties to provide products, services or otherwise
            support our business or collaborate with third parties with respect
            to development, promotion or other business activities related to a
            particular product or service. These third parties may include
            service providers of a sponsoring employer, or our own service
            providers we use to support our business and who provide IT and
            infrastructure support services, and marketing, and billing
            services. As a result, we may disclose Personal Information that we
            collect or you provide to contractors, “Business Associates” (as
            defined under HIPAA), service providers and other third parties for
            solely for purposes of providing the Services as outlined above;
            provided such third parties have agreed to comply with this Privacy
            Policy or substantially equivalent terms. We may also disclose
            Personal Information to our subsidiaries and affiliates; to a third
            party in connection with a merger, divestiture, restructuring,
            reorganization, dissolution, sale or transfer of some or all of our
            assets or other similar corporate transactions or in connection with
            a bankruptcy, liquidation or similar proceeding. We may also release
            your Personal Information to third parties as required by law, when
            we believe disclosure is necessary to comply with a legal or
            regulatory requirements, judicial proceeding, court order or legal
            process served on us, to protect the safety, rights or property of
            patients, customers, the public or Twin Health or defend Twin Health
            and its officers, directors, employees, attorneys, agents,
            contractors and partners, in connection with any legal action,
            claim, or dispute. We may also disclose Personal Information to
            enforce or apply our Terms of Use and other agreements, including
            for billing and collection purposes. We may disclose Personal
            Information to fulfill the purpose for which you provide it. For
            example, we may disclose your health reports to your employer.
            Except as set forth in this Privacy Policy or as specifically agreed
            to by you, we will not disclose any Personal Information we gather
            from you on our Services to third parties.
          </p>
          <h5>
            Your Rights Regarding Accessing and Correcting Your Information.
          </h5>
          <p>
            We can review and change your Personal Information by logging onto
            our App and visiting the Account Preferences section of our App. You
            may also notify us, through one of the means listed below in the
            Contact Us section, of any changes or errors in any Personal
            Information we have about you to ensure that it is complete,
            accurate, and as current as possible. If you wish to delete your
            Twin Health account, please contact your employer. We cannot delete
            your Personal Information except by also deleting your account with
            us. We may also not be able to accommodate your request if we
            believe it would violate any law or legal requirement or cause the
            information to be incorrect. With respect to any PHI Twin Health may
            obtain, you have certain rights under HIPAA to access your data, to
            restrict use and disclosure of it, to request communication methods,
            to request corrections to your data, to receive an accounting of
            disclosures and to receive notice of any breach. See Twin Health’s
            Notice of Privacy Practices for more information.
          </p>
          <h5>How We Keep Your Information Secure.</h5>
          <p>
            We seek to safeguard the security of your Personal Information and
            have implemented reasonable security measures consistent with
            accepted practices in the healthcare industry to protect the
            confidentiality of your Personal Information and limit accidental
            loss of, and unauthorized access to, it. We have a designated Chief
            Security Officer and have put in place a variety of information
            security measures to protect your Personal Information, including
            encryption technology, such as Secure Sockets Layer (SSL) and AES
            encryption, biometric authentication, automatic locking after
            periods of inactivity on the App to protect your Personal
            Information during data transport and at rest. However, despite our
            efforts to protect your Personal Information, there is always some
            risk that an unauthorized third party may find a way around our
            security systems or that transmissions of your Personal Information
            over the Internet will be intercepted. Unfortunately, transmission
            of information via the Internet is not completely secure. Although
            we work diligently to try and protect your Personal Information, we
            cannot guarantee the absolute security of your Personal Information,
            nor can we guarantee that information that you provide will not be
            intercepted while being transmitted to us over the Internet.
            Therefore, we urge you to also take every precaution to protect your
            Personal Information when you are on the Internet or using the
            Services. Any transmission of Personal Information is at your own
            risk. We are not responsible for circumvention of any privacy
            settings or security measures contained on the App or in your
            operating system.
          </p>
          <h5>How to Opt Out.</h5>
          <p>
            We strive to provide you with choices regarding the Personal
            Information you provide to us; however, We do not control the
            collection and use of your information collected by third parties
            described above. These third parties may aggregate the information
            they collect with information from their other customers for their
            own purposes. To opt out, please do not provide your Personal
            Information to us, or after providing your Personal Information to
            us, please send written notification to us at
            PrivacyIndia@TwinHealth.com that you no longer wish to receive
            information and communications from us or otherwise share your
            Personal Information. Some web browsers permit you to broadcast a
            signal to websites and online services indicating a preference that
            they “do not track” your online activities. Because there is no
            standard for these signals at this time, we currently do not honor
            such signals and we do not modify what information we collect or how
            we use that information based upon whether such a signal is
            broadcast or received by us. Please note that certain features of
            the Services may not be available when cookies are disabled. To
            learn more about how to manage cookies, visit
            http://www.allaboutcookies.org. You may opt-out of certain targeted
            advertising. To learn more about interest-based advertisements and
            your opt-out rights and options, visit the Digital Advertising
            Alliance and the Network Advertising Initiative websites
            (www.aboutads.info and www.networkadvertising.org). Please note that
            if you choose to opt out, you will continue to see ads, but they
            will not be based on your online activity. We do not control third
            parties’ collection or use of your information to serve
            interest-based advertising. However, these third parties may provide
            you with ways to choose not to have your information collected or
            used in this way. You can also opt out of receiving targeted ads
            from members of the NAI on its website.
          </p>
          <h5>Children’s Privacy</h5>
          <p>
            We are committed to protecting the privacy of children. The Services
            are not designed or intended for children and we do not knowingly
            collect Personal Information about children under eighteen (18)
            years old. No one under the age of 18 may provide information to or
            through the App. If you are under 18, do not use or provide any
            information on our App or on or through any of its features,
            including your name, address, telephone number, email address, or
            any screen name or user name you may use. If we learn that we have
            collected or received Personal Information from a child under 18
            years without verification of parental consent, we will delete that
            information. If you believe we might have any information about a
            child under 18, please contact us at PrivacyIndia@TwinHealth.com.
          </p>
          <h5>Third Party Websites</h5>
          <p>
            Please be aware that our App or Services may have links to
            third-party websites that may collect Personal Information about
            you. When you click on one of these third-party links, you are
            entering another website for which we have no responsibility. This
            Privacy Policy does not cover the information practices or policies
            of such third-party websites. We encourage you to read the privacy
            policies of all such websites since their privacy policies may be
            materially different than our Privacy Policy.
          </p>
          <p>
            <bold>Third Party Advertisers.</bold> In addition, we may rely on
            third-party advertisers, ad networks and ad servers to promote our
            Services. These third parties may use cookies alone or in
            conjunction with web beacons or other tracking technologies to
            collect information about our users. This may include information
            about users’ behavior on this and other apps to serve them
            interested-based (behavioral) advertising. No information you share
            within our Services or shared through Apple’s Health App will be
            shared with third-party advertisers. We do not control these third
            parties’ tracking technologies or how they may be used. If you have
            any questions about an advertisement, you should contact the
            responsible advertiser directly
          </p>
          <p>
            <bold>Changes.</bold> We reserve the right to modify the terms of
            this Privacy Policy at any time and in our sole discretion, without
            notice. When the Privacy Policy is changed, modified, and/or
            amended, the revised Privacy Policy will be posted on our App.
            Modifications will be effective immediately. You should visit this
            web page periodically to review the Privacy Policy. You accept any
            such modifications to this Privacy Policy by continued use of our
            Services after such modifications are made.
          </p>
          <p>
            <bold>Contact Us.</bold> If you would like to update your Personal
            Information, delete your account, change your preferences or have
            any questions or concerns about your privacy, you may contact us at
            PrivacyIndia@TwinHealth.com. Please note that some information may
            remain in our records after deletion of your account, including any
            information or records we are legally obligated to retain.
          </p>
          <h3 className="text-center mt-3">4. TWIN Terms of use</h3>
          <p>
            Welcome, and thank you for your interest in Twins Digital Services
            India Private Limited (“Twin”, “we”, “our,” or “us”). These Terms of
            Use (“Terms”) govern your (hereinafter “you”, “your” or “User”)
            access to our website http://www.twinhealth.com/ (the “Site”),
            access to or downloading our mobile application (the “App”), our
            related websites, databases, networks, software, including widgets
            and mobile applications (including tablet applications)
            (collectively “Platform”), and the services (each a “Service”, and
            collectively the “Services”) that we facilitate, through the
            Platform, including the healthcare services provided over the
            internet by registered medical practitioners (“Medical
            Practitioners”) and nutrition coaches (“Wellness Coaches”), and the
            supply of medication, devices and diagnostic services by third
            parties.
          </p>
          <h5>1.General</h5>
          <p>
            1.1 Our Platform provides clinical decision support to Medical
            Practitioners and facilitates other Services which help them to
            treat Type II Diabetes Mellitus and certain other chronic diseases
            and related syndromes, & to prevent the risk of developing any
            chronic conditions.Twin is not your healthcare provider and does not
            provide you any medical advice, or treatment through the Platform.
          </p>
          <p>
            1.2 Our Platform is designed only be used to help a suitable subset
            of users who are diagnosed with an Applicable Disease If you have
            been referred to our Platform by your Medical Practitioner, you will
            have been diagnosed with an Applicable Disease, and the use of our
            Platform and our Services should have been explained to you. You may
            also have signed up on our Platform directly. If so, an identified
            Medical Practitioner will carry out a first consultation with you to
            assess your suitability to use our Platform, and, if you qualify,
            explain the Services to you. If you are at any point, unclear on any
            aspect of the Platform, your treatment, any Service, or are
            otherwise dissatisfied in any manner, please speak with your Medical
            Practitioner, and if you do not receive adequate support, use the
            ‘Call Coach’ function on the Platform to indicate this.
          </p>
          <p>
            1.3 These Terms of Service (these “Terms”), the Privacy Policy and
            other applicable policies, along with terms and conditions which may
            be specified by third party service providers on our Platform, form
            part of a legally binding contract between you, Twin, the Medical
            Practitioner, Wellness Coaches and the relevant third parties, in
            relation to the provision of, and your use of, the Services
          </p>
          <p>
            1.4 By accessing the Platform, you agree to be bound by these Terms
            and other policies made available on the Site or the App, including,
            but not limited to Privacy Policy in this same document Headline 3
            and other applicable policies, along with amendments or
            modifications made by Twin at its sole discretion and posted on the
            Platform, including by way of imposing additional charges for access
            to or use of our Platform
          </p>
          <p>
            1.5 PLEASE READ THESE TERMS CAREFULLY. BY CLICKING A BUTTON OR
            CHECKING A BOX MARKED “I ACCEPT” (OR SOMETHING SIMILAR), DOWNLOADING
            OUR APP, OR OTHERWISE ACCESSING OR USING THE SERVICE, YOU
            ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY
            THESE TERMS, AS MODIFIED OR AMENDED BY TWIN FROM TIME TO TIME FOR
            ITS SERVICE USERS GENERALLY, AND TO THE COLLECTON AND USE OF YOUR
            INFORMATION AS SET FORTH IN TWIN PRIVACY POLICY LOCATED AT THE SITE
            OR THE APP.
          </p>
          <p>
            1.6 IF YOU ARE NOT ELIGIBLE TO USE THE SERVICE PER THESE TERMS, OR
            IF YOU DO NOT AGREE TO THESE TERMS, THEN YOU ARE NOT ALLOWED TO USE
            THE SERVICE AND YOU SHOULD LET YOUR MEDICAL PRACTITIONER KNOW AT
            ONCE.
          </p>
          <p>
            1.7 You acknowledge and agree that the Platform or the Services are
            not intended for emergency situations. In the event of a medical
            emergency, please call appropriate emergency responders or visit a
            registered medical practitioner. The Platform is also not intended
            to facilitate the treatment of diseases other than the Applicable
            Diseases.
          </p>
          <p>
            1.8 Twin may make modifications to these Terms that apply to its
            users generally from time to time. The version of these Terms posted
            at the Site or on the App is the one that governs your use of the
            Service. You are therefore advised to refer to them each time before
            making use of the Service. Please also watch for alerts from us.
            Your continued use of the Site and the App is subject to the then
            current version of the Terms on the Site. We may require you to
            provide your consent to the updated Terms in a specified manner, but
            where no such consent is sought, your continued use of the Site and
            App, following changes to the Terms, will constitute your express
            acceptance of those Terms.
          </p>
          <p>
            1.9 The App is licensed, not sold to you, the App and Services are
            provided “as is” without warranties of any kind and Twin’s liability
            to you is very limited as described below.
          </p>
          <p>
            1.10 Your use of the Services is subject to separate third-party
            terms of service and fees, including, without limitation, the terms
            of service and data, SMS, MMS, and other fees of your mobile network
            operator (the “Carrier”), the terms of service of pharmacies,
            diagnostic centers or other third party service providers on the
            Platform, or facilitated through the Platform, compliance with which
            is your sole responsibility.
          </p>
          <p>
            1.11 You may delete your account and discontinue your use of our
            Platform at any time. Similarly, we have the right to withdraw our
            Platform, or any Service at any time without assigning any reason
            whatsoever. Twin does not guarantee that any Service will be
            available on a round-the-clock basis or that any Service will remain
            accessible.
          </p>
          <p>
            1.12 These Terms are published in compliance with the provisions of
            Indian laws, including: (a) the Indian Contract Act, 1872; (b) the
            Information Technology Act, 2000 and rules, guidelines and
            clarifications thereunder; (c) the Drugs and Cosmetics Act, 1940 and
            Drugs and Cosmetics Rules, 1945; (d) the Drugs and Magic Remedies
            (Objectional Advertisements) Act, 1954; (e) the National Medical
            Commission Act, 2019 and rules, regulations and guidelines framed/
            recognized thereunder; and (f) Pharmacy Act, 1948.
          </p>
          <h5>1.Use of Our Services.</h5>
          <p>1.1Eligibility.</p>
          <p>
            (a) By accepting these Terms, you represent and warrant to us that
            you have the capacity to enter into a legally binding contract under
            applicable law, in particular, the Indian Contract Act, 1872. You
            further represent: (a) you are at least 18 (eighteen) years of age;
            (b) you have not previously been suspended or removed from the
            Service; (c) your registration and your use of the Service is in
            compliance with all applicable laws and regulations; (d) you are
            physically located within a jurisdiction in which we offer our
            Service, including, without limitation, when utilizing the Service
            to receive advice from a Medical Practitioner; (f) you are not
            attempting to use the Service on behalf of any governmental unit;
            and (g) all information you provide will be truthful, accurate and
            complete. Twin reserves the right to terminate your account or
            refuse to provide you access to the Site, App or our Service if it
            is brought to our notice or if it is discovered that you are under
            the age of 18 (eighteen) years or that you have been convicted of an
            offence involving moral turpitude or any offence under the Indian
            Penal Code, 1860.
          </p>
          <p>
            (b) We reserve the right to refuse access to use any Service offered
            on the Platform to new users or to terminate access granted to
            existing users at any time without according any reasons for doing
            so and you shall have no right to object to the same.
          </p>
          <p>
            (c) You are restricted to have more than one active account on the
            Site or App. Additionally, you are prohibited from selling, trading,
            or otherwise transferring your account to another party.
          </p>
          <p>
            (d) The use of the Site or App and the Services is designed to be
            compliant with the laws of India. You are permitted to use this Site
            or the App and any Service even if you reside outside India. We do
            provide any Service on the Platform to users located outside India.
          </p>
          <h5>1.2 Platform Suitability.</h5>
          <p>
            You may find it difficult to determine if the Platform is the most
            appropriate way for you to obtain medical care and treatment. To
            assist you in determining whether the Platform is a fit for your
            needs, the Medical Practitioner may ask a series of initial
            questions during registration. Based on your responses to these
            questions, the Medical Practitioner may determine that the Platform
            will not be made available to you. In such a case, you will be so
            advised. If this occurs, your registration information may remain on
            file with the Platform, but you will not be able to further utilize
            the Platform at that time. You can always return to the Site or the
            App at a later time to determine whether your eligibility has
            changed. In addition, even if you are determined to be eligible to
            utilize the Platform, the Medical Practitioner may, during the
            course of your use of the Platform, determine that your medical
            condition and/or treatment requires an in-person examination or a
            procedure and/or that the Platform is otherwise not appropriate for
            you. In such a case, you may receive a notice from your Medical
            Practitioner notifying you that you should seek medical care or
            treatment outside of the Platform and/or that you may be unable to
            continue using the Platform, among other things.
          </p>
          <h5>1.3 Twin Platform and Services.</h5>
          <p>
            Subject to these Terms, you are authorized to use the Platform and
            avail any Service, on a non-exclusive, limited, non-transferable,
            revocable basis, along with the Twin Materials if given solely for
            your personal, non-commercial use. Twin reserves all rights not
            expressly granted herein in the Services and the Twin Materials.
            Twin may terminate this authorization at any time for any or no
            reason. After any termination, these Terms, including all of your
            obligations under these Terms prior to such termination, will remain
            in full force and effect and will govern any and all disputes
            arising out of your access and use of the Service and the Twin
            Materials.
          </p>
          <h5>1.4 Accounts and Registration</h5>
          <p>
            Subject to your eligibility to use the Platform and compliance with
            these Terms, you may use the Platform and any Service. Some features
            of the Platform may be available without registration; however, to
            access most features of the Platform, you must register for an
            account. When you register, you will be asked to provide a password.
            You are solely responsible for maintaining the confidentiality of
            your account and password. You agree to accept responsibility for
            all activities that occur under your account. If you have reason to
            believe that your account has been compromised or corrupted or is no
            longer secure, then you must immediately notify us at
            care@twinhealth.com. You must ensure that you exit or log out of
            your account at the end of each session. Furthermore, Twin reserves
            the right to take any and all action, as it deems necessary,
            regarding the security of our Platform and your account and password
            information. Under no circumstances will Twin or any of its
            officers, directors, employees, consultants, agents, suppliers and
            customers associated with the Service, or any affiliated individuals
            or entities, including the Medical Practitioner, Wellness Coach,
            and/or any professional corporation that employs or contracts with
            any Medical Practitioners be held liable to you for any liabilities
            or damages resulting from your failure to comply with these Terms,
            including any failure by you to keep your account and password or
            other information secure. Twin may hold you liable for losses
            incurred by itself or any other user or visitor due to authorized or
            unauthorized use of your account or as a result of your failure in
            keeping your account and password confidential.
          </p>
          <h5>1.5Prohibited Conduct.</h5>
          <p>In consideration for access to the Platform, you agree not to:</p>
          <p>(a) use the Platform to compete with the Twin in any manner;</p>
          <p>
            (b) use the Platform for any illegal purpose, or in violation of any
            applicable law or regulation, including, without limitation, any
            local, state, provincial, territorial, national, or international
            law;
          </p>
          <p>
            (c) use the Platform in any manner that would defame, abuse, harass,
            threaten or otherwise violate the legal rights of others;
          </p>
          <p>
            (d) violate, or encourage others to violate, any right of a third
            party, including by infringing or misappropriating any third-party
            intellectual property or privacy right;
          </p>
          <p>
            (e) upload any files that contain software or other material
            protected by applicable intellectual property laws, unless you own
            or control the rights thereto or have received all necessary
            consents;
          </p>
          <p>
            (f) post, upload, or distribute any User Content or other content
            that is unlawful, defamatory, libelous, inaccurate, or that a
            reasonable person could deem to be objectionable, profane, indecent,
            pornographic, harassing, threatening, embarrassing, hateful, or
            otherwise inappropriate;
          </p>
          <p>
            (g) interfere with security-related features of the Site or the App,
            including by: (i) disabling or circumventing features that prevent
            or limit use or copying of any content; or (ii) reverse engineering
            or otherwise attempting to discover the source code of any portion
            of the Platform except to the extent that the activity is expressly
            permitted by applicable law;
          </p>
          <p>
            (h) interfere with the operation of the Site or the App or enjoyment
            of the Platform of any user, Medical Practitioner or Wellness Coach,
            including by: (i) uploading or otherwise any disseminating virus,
            adware, spyware, worm, or other malicious code; (ii) making any
            unsolicited offer or advertisement; (iii) attempting to collect
            personal information about others; (iv) interfering with or
            disrupting any network, equipment, or server connected to or used to
            provide any Service; (v) violating any regulation, policy, or
            procedure of any network, equipment, or server; or (vi) manipulating
            or otherwise displaying the Service by using framing, mirroring or
            similar navigational technology;
          </p>
          <p>
            (i) attempt to gain unauthorized access to any portion of feature of
            the Site or the App or any other systems or networks connected to
            the Site or the App, to servers operated by us, or to any part of
            the Site or the App, including by way of hacking, password mining or
            any other illegitimate means;
          </p>
          <p>
            (j) Probe, scan or test the vulnerability of the Site or the App or
            any network connected thereto, nor breach the security or
            authentication measures on the Site or the App or any network
            connected to the Site or the App. You may not reverse look-up, trace
            or seek to trace any information on any other user, of or visitor to
            the Site or the App, or any other customer, including any account
            not owned by you, to its source, or exploit the Platform or
            information made available or offered by or through the Platform, in
            any way whether or not the purpose is to reveal any information,
            including but not limited to personal identification information,
            other than your own information, as provided for by the Platform;
          </p>
          <p>
            (k) engage in any fraudulent activity including impersonating any
            person or entity, claiming a false affiliation, accessing any other
            Service, without permission, or falsifying your age or date of birth
            or any other information provided or submitted to the Service;
          </p>
          <p>
            (l) sell or otherwise transfer the access to the Platform or any
            Twin Materials; or attempt to do any of the acts described in this
            section, or assist or permit any person in engaging in any of the
            acts described in this section.
          </p>
          <h5>2.Mobile Software.</h5>
          <p>
            2.1 Mobile Software and Equipment. We may make available the App or
            other software to access the Platform via a mobile device (“Mobile
            Software”). To use the Mobile Software, you must have a mobile
            device that is compatible with the Mobile Software. Twin does not
            provide you with equipment to use the App and does not warrant that
            the Mobile Software will be compatible with your mobile device. You
            may use data in connection with the Mobile Software and may incur
            additional charges from your wireless provider. You are responsible
            for complying with any third-party terms of service and paying all
            fees charged by third parties to access and use the App, including,
            without limitation, the payment of all applicable fees associated
            with any Carrier service plan you use in connection with your use of
            the Platform (such as data, SMS, MMS, roaming, and other applicable
            fees charged by the Carrier). You agree that you are solely
            responsible for any such charges. Twin hereby grants you a
            non-exclusive, non-transferable, revocable license to use a compiled
            code copy of the Mobile Software on mobile devices owned or leased
            solely by you, and in accordance with the features made available to
            you. You may not: (a) modify, disassemble, decompile or reverse
            engineer the Mobile Software, except to the extent that such
            restriction is expressly prohibited by law; (b) rent, lease, loan,
            resell, sublicense, distribute or otherwise transfer the Mobile
            Software to any third party or use the Mobile Software to provide
            time sharing or similar services for any third party; (c) make any
            copies of the Mobile Software; (d) remove, circumvent, disable,
            damage or otherwise interfere with security-related features of the
            Mobile Software, features that prevent or restrict use or copying of
            any content accessible through the Mobile Software, or features that
            enforce limitations on use of the Mobile Software; or (e) delete the
            copyright and other proprietary rights notices on the Mobile
            Software. You acknowledge that Twin may from time to time make
            available upgraded versions of the Mobile Software, and may
            automatically electronically upgrade the version of the Mobile
            Software that you are using on your mobile device. You consent to
            such automatic upgrading on your mobile device, and agree that these
            Terms will apply to all such upgrades. Any third-party code that may
            be incorporated in the Mobile Software is covered by the applicable
            open source or third-party license, if any, authorizing use of such
            code. The foregoing license grant is not a sale of the Mobile
            Software or any copy thereof, and Twin or its third-party partners
            or suppliers retain all right, title, and interest in the Mobile
            Software (and any copy thereof). Any attempt by you to transfer any
            of the rights, duties or obligations hereunder, except as expressly
            provided for in these Terms, is void. Twin reserves all rights not
            expressly granted under these Terms. The Mobile Software may be
            subject to the import and export laws of various countries. You
            agree to comply with all applicable laws related to use of the
            Mobile Software and the Service.
          </p>
          <p>
            2.2 Twin’s SMS or Text Messaging Services. By using the Platform,
            you are automatically enrolled to receive SMS/text messages from
            Twin regarding account-related news and alerts and/or offers for
            Twin products and services. By enrolling in Twin’s SMS/text
            messaging service, you hereby authorize Twin to send you SMS/text
            messages and agree to receive the text messages from us on your
            mobile phone number provided, and you certify that your mobile
            number provided is true and accurate and that you are authorized to
            enroll the designated mobile number to receive such text messages.
            You acknowledge and agree that the text messages may be sent using
            an automatic telephone dialing system and that standard message and
            data rates apply. To unsubscribe from text messages at any time,
            reply to the message you received with the text STOP. You consent
            that following such a request to unsubscribe, you may receive one
            final text message from Twin confirming your request. For help under
            this section, contact us at care@twinhealth.com. You agree that your
            contact number supplied by you as part of registration information
            is not part of any ‘do not call’ registry or equivalent and that
            unless otherwise specified by you, we may use such information to
            send you communication, notices or alerts which may be
            transactional, informational or promotional in nature, from time to
            time.
          </p>
          <p>
            2.3 Mobile Software provided from App Store by Apple. The following
            applies to any Mobile Software you acquire from the App Store (“App
            Store-Sourced Software”): You acknowledge and agree that these Terms
            are solely between you and Twin, not Apple, and that Apple has no
            responsibility for the App Store-Sourced Software or content
            thereof. Your use of the App Store-Sourced Software must comply with
            the App Store Terms of Service. You acknowledge that Apple has no
            obligation whatsoever to furnish any maintenance and support
            services with respect to the App Store-Sourced Software. In the
            event of any failure of the App Store-Sourced Software to conform to
            any applicable warranty, you may notify Apple, and Apple will refund
            the purchase price for the App Store-Sourced Software to you; to the
            maximum extent permitted by applicable law, Apple will have no other
            warranty obligation whatsoever with respect to the App Store-Sourced
            Software, and any other claims, losses, liabilities, damages, costs
            or expenses attributable to any failure to conform to any warranty
            will be solely governed by these Terms and any law applicable to
            Twin as provider of the software. You acknowledge that Apple is not
            responsible for addressing any claims of you or any third party
            relating to the App Store-Sourced Software or your possession and/or
            use of the App Store-Sourced Software, including, but not limited
            to: (a) product liability claims; (b) any claim that the App
            Store-Sourced Software fails to conform to any applicable legal or
            regulatory requirement; and (c) claims arising under consumer
            protection or similar legislation; and all such claims are governed
            solely by these Terms and any law applicable to Twin as provider of
            the software. You acknowledge that, in the event of any third-party
            claim that the App Store-Sourced Software or your possession and use
            of that App Store-Sourced Software infringes that third-party’s
            intellectual property rights, Twin, not Apple, will be solely
            responsible for the investigation, defense, settlement and discharge
            of any such intellectual property infringement claim to the extent
            required by these Terms. You and Twin acknowledge and agree that
            Apple, and Apple’s subsidiaries, are third-party beneficiaries to
            these Terms as relates to your license of the App Store-Sourced
            Software, and that, upon your acceptance of the terms and conditions
            of these Terms, Apple will have the right (and will be deemed to
            have accepted the right) to enforce these Terms as they relate to
            your license of the App Store-Sourced Software against you as a
            third-party beneficiary thereof.
          </p>
          <p>
            2.4 Mobile Software provided from Google Play Store. The following
            applies to any Mobile Software you acquire from the Google Play
            Store (“Google-Sourced Software”): (a) you acknowledge that these
            Terms are between you and Twin only, and not with Google, Inc.
            (“Google”); (b) your use of Google-Sourced Software must comply with
            Google’s then-current Google Play Store Terms of Service; (c) Google
            is only a provider of the Google Play Store where you obtained the
            Google-Sourced Software; (d) Twin, and not Google, is solely
            responsible for its Google-Sourced Software; (e) Google has no
            obligation or liability to you with respect to Google-Sourced
            Software or these Terms; and (f) you acknowledge and agree that
            Google is a third-party beneficiary to these Terms as it relates to
            Twin’s Google-Sourced Software.
          </p>
          <p>
            2.5 All the data is collected from the third party sensors to
            monitor your blood glucose, hypertension, ketone level, sleep,
            steps, heart rate, BMI, muscle mass & water content. The data you
            are providing with these third party sensors are protected by HIPPA
            policy.
          </p>
          <h5>3. Consent to Use of Telemedicine Services.</h5>
          <p>
            3.1 As part of the Services on the Platform, Twin will facilitate
            the connection between you and the Medical Practitioner.
            Telemedicine services involve the delivery of healthcare using
            electronic communications, information technology or other means
            between the Medical Practitioner and a patient who are not in the
            same physical location. While the provision of telemedicine services
            may offer certain potential benefits, there are also potential risks
            associated with the use of telemedicine services.
          </p>
          <p>
            3.2 You acknowledge that you have been provided with necessary
            information and based on the same, you provide your consent,
            authorization for the Medical Practitioner, associated doctors,
            assistants to provide remote telehealth services, including use of
            the Platform offered by Twin. You understand that you may withdraw
            your consent and discontinue treatment at any time, however, Twin
            may not provide you with the Service in such cases. Twin may suspend
            your account or terminate your access to the Service if you withdraw
            consent. You acknowledge that the Medical Practitioner has informed
            you of alternative methods and therapies that you want to undertake
            in comparative to the proposed treatment / intervention, their
            respective benefits, material risks and disadvantages, if any. You
            understand that the proposed telemedicine treatment has certain
            material risks / complications, and have been provided with the
            requisite information about the same, including that there are other
            undefined, unanticipated, unexplainable risks / complications that
            may occur during or after the proposed treatment / intervention. You
            also confirm that the Medical Practitioner has answered all
            questions to your satisfaction regarding the proposed treatment,
            however, that despite best efforts there can be no assurance about
            the results or outcome of the proposed treatment / intervention and
            that you have not been given any guarantee or warranty about the
            results or outcome of the proposed treatment / intervention.
          </p>
          <p>
            3.3 In order to utilize all features of the Platform, you will be
            required to review the Terms and other policies and provide consent
            to the use of telemedicine services from a Medical Practitioner and
            Wellness Coach assigned by us or your Medical Practitioner, if you
            have been referred to our Service, and we may reasonably rely on
            such consent. If you have not provided consent for the Service, Twin
            reserves the right to disable your access to or use of our App and
            Site. You should seek emergency help or follow-up care when
            recommended by a Medical Practitioner or when otherwise needed, and
            continue to consult with your primary care physician and other
            healthcare professionals as recommended. Among the intended benefits
            of our Platform are improved visibility to healthcare professionals
            and convenience. However, as with any healthcare service, there are
            potential risks associated with the use of telehealth services. The
            risks include, but may not be limited to: In rare cases, information
            transmitted may not be sufficient (e.g., poor resolution of images)
            to allow for appropriate healthcare decision making by the Medical
            Practitioner; Delays in evaluation or treatment could occur due to
            failures of the electronic equipment. If this happens, you may be
            contacted by phone or other means of communication; In rare cases, a
            lack of access to all of your health records may result in adverse
            drug interactions, hypoglycemic/hyperglycemic events, allergic
            reactions or other judgment errors. These conditions elucidated
            above are indicative and not exhaustive.; Although the electronic
            systems we use will incorporate network and software security
            protocols to protect the privacy and security of health information,
            security protocols can fail or be breached, causing a breach of
            privacy of personal health information.
          </p>
          <p>
            3.4 By accepting these Terms, you acknowledge that you understand
            and agree with the following: Although you may reasonably expect the
            intended benefits from the use of the Services, no benefits or
            results can be guaranteed or assured. You understand that the laws
            that protect the privacy and security of health information may
            apply to aspects of the Services, and you have read the Privacy
            Policy, which describes these protections in more detail. The
            Medical Practitioner may determine that the Platform is not
            appropriate for some or all of your needs, and accordingly may elect
            not to facilitate telehealth services to you through the Service.
          </p>
          <p>
            3.5 You undertake to report any instance of non-compliance of
            applicable law, as part of the consultation with Medical
            Practitioner, interaction with Wellness Coach and other processes or
            systems on the Platform, including the Ethics Regulations and
            Telemedicine Guidelines at the earliest to Twin and provide your
            fullest cooperation and support to fulfil reporting obligations
            applicable to us.
          </p>
          <p>
            3.6 Wherever applicable, your use of our Platform in addition to
            these Terms and the Privacy Policy is also governed by the contract
            entered into between you and your Medical Practitioner.
          </p>
          <h5>4. Privacy Policy.</h5>
          <p>
            Twin understands the importance of confidentiality and privacy
            regarding your information. Please see our Privacy Policy located on
            our website for a description of how we may collect, use and
            disclose your personal information. By accepting these Terms, in
            addition to any other applicable privacy notices, you acknowledge
            and agree that you have read and understand our Privacy Policy.
          </p>
          <h5>5. Pricing, Charges, and Payment Terms.</h5>
          <p>
            5.1 Health Plan and Employer Provider Pricing and Payment Terms. If
            you are a health plan or employer provider, or if you are an
            individual using the Platform under a health plan or employer
            provider, the pricing and payment terms are between the health plan
            or employer provider and Twin and such terms are set forth in a
            separate agreement between the health plan or employer provider and
            Twin.
          </p>
          <p>
            5.2 Individual Pricing and Payment Terms. If you are not accessing
            or using the Platform under your health plan or employer provider,
            then you are accessing or using the Service on an individual basis.
            If you elect to use aspects of the Platform through your Medical
            Practitioner, you agree to the pricing and payment terms as set
            forth in your account or made available to you online. Twin may add
            new services for additional fees and charges and to add or amend
            fees and charges for existing services. Any change to our pricing or
            payment terms will become effective in the billing cycle following
            notice of such change to you as provided in these Terms. All fees
            payable on the Platform are payable to Twin directly (“Fees”).
          </p>
          <p>
            5.3 Fees. You agree and acknowledge that the Fees payable by you at
            the time of registration entitles you to avail the Services, subject
            to compliance with the Terms and applicable laws, for the period the
            payment has been made for. At the time of payment, you may be
            provided with various payment options, including payment in full or
            in a staggered manner, as may be specified from time to time. You
            agree that your continued access to the Platform and the Services is
            subject to timely payment of your Fees and Twin reserves the right
            to deny you access to the Platform or any of its Services in the
            event of your default to pay any scheduled amount or any renewal
            fees payable.
          </p>
          <p>
            5.4 Refunds. You may cancel the any Service on the Platform or
            account on notice to us (at care@twinhealth.com); however there are
            no refunds for cancellation. In the event that we suspend or
            terminate your account or these Terms for good reason (e.g., your
            violation of these Terms), you understand and agree that you will
            receive no refund.
          </p>
          <p>
            5.5 Access and Connectivity. You are responsible for providing and
            maintaining, at your own risk, option and expense, appropriate
            software and hardware capabilities (consistent with any technical,
            quality or other requirements described in the App or the Site) to
            enable use of the Platform. Twin reserves the right to change the
            access configuration, including any software, hardware or other
            requirements of the Service at any time without prior notice.
          </p>
          <p>
            5.6 Medical Equipment. The Medical Practitioner may prescribe
            medication and use of medical equipment on the Platform, including
            medical supplies, e.g., diabetic test supplies, including blood
            sugar and blood ketone strips, which needs to be sourced by you,
            either through our Platform or through external vendors. The timing,
            fees, quantity and terms of all such supplies shall be at the Medial
            Practitioner’s sole discretion.{" "}
          </p>
          <h5>6. User Content</h5>
          <p>
            6.1 User Content Generally. Certain features of the Platform may
            permit users to upload content to the Platform (including without
            limitation the patient portal) or allow us or your Medical
            Practitioner to input information into the Platform, including
            messages, reviews, photos, video, images, folders, data, text, and
            other types of information (“User Content”) and to publish User
            Content on the Platform.
          </p>
          <p>
            6.2 Limited License Grant to Twin. By posting or publishing User
            Content, you grant Twin a worldwide, non-exclusive, royalty-free
            right and license (with the right to sublicense) to use, host,
            store, transfer, display, perform, reproduce, modify for the purpose
            of formatting for display, and distribute your User Content, in
            whole or in part, in any media formats and through any media
            channels (now known or hereafter developed); all without obligation
            to notify or compensate you or attribute such Content to you.
          </p>
          <p>
            6.3 Limited License Grant to Other Users. By posting and sharing
            User Content with another user of the Platform, you grant that user
            a non-exclusive license to access and use that User Content as
            permitted by these Terms and the functionality of the Platform.
          </p>
          <p>
            6.4 User Content Representations and Warranties. You are solely
            responsible for your User Content and the consequences of posting or
            publishing User Content. By posting or publishing User Content, you
            affirm, represent, and warrant that: you are the creator and owner
            of, or have the necessary licenses, rights, consents, and
            permissions, to use and to authorize Twin and users of the Service
            to use and distribute your User Content as necessary to exercise the
            licenses granted by you and in the manner contemplated by Twin, the
            Platform, and these Terms; and your User Content, and the use of
            your User Content as contemplated by these Terms, does not and will
            not: (i) infringe, violate, or misappropriate any third-party right,
            including any copyright, trademark, patent, trade secret, moral
            right, privacy right, right of publicity, or any other intellectual
            property or proprietary right; (ii) slander, defame, libel, or
            invade the right of privacy, publicity or other property rights of
            any other person; or (iii) cause Twin to violate any law or
            regulation.
          </p>
          <p>
            6.5 User Content Disclaimer. We are under no obligation to edit or
            control User Content that you or other users post or publish and
            will not be in any way responsible or liable for User Content. Twin
            may, however, at any time and without prior notice, screen, remove,
            edit, or block any User Content that in our sole judgment violates
            these Terms or is otherwise objectionable. You understand that when
            using the Platform you will be exposed to User Content from a
            variety of sources and acknowledge that User Content may be
            inaccurate, offensive, indecent, or objectionable. You agree to
            waive, and do waive, any legal or equitable right or remedy you have
            or may have against Twin with respect to User Content. We expressly
            disclaim any and all liability in connection with User Content. If
            notified by a user or content owner that User Content allegedly does
            not conform to these Terms, we may investigate the allegation and
            determine in our sole discretion whether to remove the User Content,
            which we reserve the right to do at any time and without notice. For
            clarity, Twin does not permit or encourage copyright-infringing
            activities on the Platform.
          </p>
          <h5>7. Intellectual Property Violation.</h5>
          <p>
            7.1 Notification. We respect the rights of third parties in their
            intellectual property. If you have an intellectual property
            rights-related complaint about material posted on the Platform, you
            may contact us at the following address: Twins Digital Services
            India Private Limited, E Block, 9th Floor, IITM Research Part,
            Kanagam Road, Taramani, Chennai, Tamilnadu, India- 600 113. E-mail:
            care@twinhealth.com. Any notice alleging that materials hosted by or
            distributed through the Service infringe intellectual property
            rights must include the following information: (a) an electronic or
            physical signature of the person authorized to act on behalf of the
            owner of the copyright or other right being infringed; (b) a
            description of the copyright-protected work or other intellectual
            property right that you claim has been infringed; (c) a description
            of the material that you claim is infringing and where it is located
            on the Platform; (d) your address, telephone number, and email
            address; (e) a statement by you that you have a good-faith belief
            that the use of those materials on the Service is not authorized by
            the copyright owner, its agent, or the law; and (f) a statement by
            you that the above information in your notice is accurate and that,
            under penalty of perjury, you are the copyright or intellectual
            property owner or authorized to act on the copyright or intellectual
            property owner’s behalf.
          </p>
          <p>
            7.2 Repeat Violations. Twin will promptly terminate without notice
            the accounts of users that are determined by Twin to be a “repeat
            violator.” A repeat violator is a user who has been notified of
            infringing activity or has had User Content removed from the
            Platform at least twice.
          </p>
          <p>
            7.3 Nothing contained in this clause may be perceived as a
            limitation to the ability of Twin to suspend user accounts for user
            content violations in the first instance.
          </p>
          <h5>8. Third-Party Services and Linked Websites</h5>
          <p>
            The Platform may also allow you to display, use, include or make
            available content, data, information, applications or materials from
            third parties, including, without limitation, content, data,
            information, applications or materials (“Third Party Materials”). By
            requesting an import of Third Party Materials, you agree that we may
            import that information from the applicable third-party service.
            Twin may also provide tools through the Platform that enable you to
            export information, including User Content, to third-party services.
            By requesting an export of information, including User Content, to
            third-party services, you agree that we may export that information
            to the applicable third-party service. Third-party services are not
            under our control, and we are not responsible for any third-party
            service’s use of information you provide or information you export
            or disclose. When you use any Third Party Materials or third-party
            services, you do so at your own risk and you understand and agree
            that you are solely responsible for reading, understanding, and
            complying with any terms of use and/or privacy policies that apply
            to such third parties’ terms of use and privacy policy. The Platform
            may also contain links to third-party websites. Linked websites are
            not under our control, and we are not responsible for their content.
            Twin is not responsible or liable for the availability, reliability,
            content, functions, accuracy, legality, appropriateness, services,
            materials or any other aspect of such Third Party Materials or
            third-party websites that link to or from the Service or are
            otherwise referenced on the Platform. Twin also does not accept any
            responsibility for technical failures or for unauthorized access of
            user transmissions by any third parties. If you choose to use Third
            Party Materials and third-party websites together with the Platform,
            such use is entirely at your discretion and risk.
          </p>
          <h5>
            9. Termination of Use; Discontinuation and Modification of the
            Service.
          </h5>
          <p>
            If you violate any provision of these Terms, your permission from to
            use the Platform will terminate automatically. In addition, Twin may
            in its sole discretion terminate your user account on the Platform
            or suspend or terminate your access to the Platform at any time,
            with or without notice. We also reserve the right to modify or
            discontinue the Platform, any features thereof, or any Services at
            any time without notice to you. We will have no liability whatsoever
            on account of any change to the Platform or any suspension or
            termination of your access to or use of the Platform. You may
            terminate your account at any time by contacting customer service at
            support@twinshealth.com. If you terminate your account, you remain
            obligated to pay all outstanding fees, if any, relating to your use
            of the Platform incurred prior to termination.
          </p>
          <h5>10. Additional Terms. </h5>
          <p>
            Your use of the Platform is subject to all additional terms,
            policies, rules, or guidelines applicable to the Platform or certain
            features of the Platform that we may post on or link to from the
            Service (the “Additional Terms”), such as end-user license
            agreements for any downloadable software applications, or rules that
            are applicable to a particular feature or content on the Platform.
            All Additional Terms are incorporated by this reference into, and
            made a part of, these Terms.
          </p>
          <h5>11. Modification of these Terms</h5>
          <p>
            We reserve the right, at our sole discretion, to change or update
            these Terms from time to time. Please check these Terms periodically
            for changes or updates. Immaterial modifications are effective upon
            publication. If a change or update to these Terms materially
            modifies your rights or obligations, we will update the “last
            updated” date at the top of this page and make reasonable efforts to
            notify you that material changes have been made to the Terms. Your
            continued use of the Platform after any such change or update
            constitutes your acceptance of the new Terms. If you do not agree to
            any of these Terms or any future Terms, do not use or access (or
            continue to access) the Platform.
          </p>
          <h5>12. Ownership; Proprietary Rights</h5>
          <p>
            The Platform is owned and operated by Twin Health Inc.. The visual
            interfaces, trademarks, graphics, names, logos, marks, content
            (other than User Content), design, compilation, information, data,
            computer code (including source code or object code), products,
            software, services, and all other elements of the Service (“Twin
            Materials”) are the intellectual property of Twin Inc. and are
            protected by intellectual property and other laws. All Twin
            Materials contained in the Platform and all trademarks, service
            marks, patents, copyrights and any and all other intellectual
            property rights and proprietary material related thereto are, and
            will remain, the exclusive property of Twin Inc. or our third-party
            licensors. Except as expressly authorized by Twin Inc., you may not
            copy, republish, post, display, translate, transmit, reproduce,
            distribute or otherwise make use of the Twin Materials. The
            information displayed on the Platform is for your personal use only.
            Twin Inc. reserves all rights to the Twin Materials not granted
            expressly in these Terms. If at any time, you make any submissions,
            including but not limited to suggestions, feature ideas, bug
            reports, comments, you assign all rights in these submissions to us.
            Twin Inc. shall be entitled to use such submissions for any purpose,
            whatsoever, without compensation to the maker. In any event, such
            submissions are not and will not be treated as confidential and we
            will not be liable for any disclosure of submissions.
          </p>
          <p>
            <h5>13. No Medical Advice.</h5> We act as a technology platform to
            connect you with Medical Practitioners who may be available to
            provide you with certain non-emergency medical care, however Twin is
            not itself a healthcare provider, and the Medical Practitioners are
            solely responsible for providing you with any medical care and
            treatment you receive in connection with your use of the Service. We
            do not manage, control or interfere with the practice of medicine by
            the Medical Practitioners, each of whom is responsible for the
            professional medical services he or she provides. The information
            provided by Twin as part of the Twin Materials and through the
            Platform is for general informational purposes only. None of the
            Twin Materials should be considered medical advice or an
            endorsement, representation or warranty that any particular
            medication or treatment is safe, appropriate, or effective for you.
          </p>
          <p>
            <h5>14. Indemnity.</h5> You agree that you will be responsible for
            your use of the Platform, and you agree to defend and indemnify Twin
            from and against every claim, liability, damage, loss, and expense,
            including reasonable attorneys’ fees and costs, arising out of or in
            any way connected with: (a) your access to, use of, or alleged use
            of, the Platform; (b) your violation of any portion of these Terms,
            any representation, warranty, or agreement referenced in these Terms
            or any applicable law or regulation; (c) your violation of any
            third-party right, including any intellectual property right or
            publicity, confidentiality, other property, or privacy right; or (d)
            any dispute or issue between you and any third party. We reserve the
            right, at our own expense, to assume the exclusive defense and
            control of any matter otherwise subject to indemnification by you
            (without limiting your indemnification obligations with respect to
            that matter), and in that case, you agree to cooperate with our
            defense of that claim.
          </p>
          <p>
            <h5>15. Non-Secure Communications.</h5> Twin is required to comply
            with applicable healthcare privacy and security laws and maintain
            safeguards to protect the security of your health information.
            Additionally, the information you provide to your Medical
            Practitioner during a medical consultation is legally confidential,
            except for certain legal exceptions. We devote considerable effort
            toward ensuring that your personal information is secure.
            Information regarding our use of health and other personal
            information is provided in our Privacy Policy and Notice of Privacy
            Practices, currently available on our website. As part of providing
            you the Services, we will communicate to you via email and text
            message. Email and text messages are not secure methods of
            communication and Twin cannot ensure the security or confidentiality
            of messages sent by email and/or text message. Information,
            including personal health information, transmitted by email or text
            message is not encrypted and could be read by a third party. If you
            would prefer not to exchange personal health information via email
            or text message, please notify us at PrivacyIndia@TwinHealth.com.
          </p>
          <h5>16. Disclaimers; No Warranties.</h5>
          <p>
            WHILE THE PLATFORM FACILITATES ACCESS TO CERTAIN MEDICAL
            PRACTITIONERS FOR NON-EMERGENCY MEDICAL CARE, TWIN IS NOT A
            HEALTHCARE PROVIDER AND CANNOT AND DOES NOT DIAGNOSE OR TREAT YOUR
            HEALTH CONDITIONS. TWIN PROVIDES NO ENDORSEMENT, REPRESENTATION OR
            WARRANTY THAT ANY PARTICULAR MEDICATION OR TREATMENT USED BY THE
            MEDICAL PRACTITIONERS IS OR WILL BE SAFE, EFFECTIVE OR APPROPRIATE
            FOR YOU. DO NOT USE THE PLATFORM OR SERVICES FOR MEDICAL
            EMERGENCIES. IF YOU THINK YOU MAY HAVE A MEDICAL EMERGENCY, CALL THE
            CONTACT NUMBER FOR EMERGENCY SERVICES IN YOUR REGION IMMEDIATELY.
            THE PLATFORM AND ALL TWIN’ MATERIALS AND ALL CONTENT AVAILABLE
            THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE”
            BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR
            IMPLIED. TO THE EXTENT ALLOWED BY LAW, TWIN DISCLAIM ALL WARRANTIES
            OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE PLATFORM
            AND ALL TWIN MATERIALS AND CONTENT AVAILABLE THROUGH THE PLATFORM,
            INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR
            A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT;
            AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR
            TRADE. TWIN DO NOT WARRANT THAT THE PLATFORM OR ANY PORTION OF THE
            SERVICE, OR ANY TWIN MATERIALS OR CONTENT OFFERED THROUGH THE
            PLATFORM, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES,
            OR OTHER HARMFUL COMPONENTS, AND DO NOT WARRANT THAT ANY OF THOSE
            ISSUES WILL BE CORRECTED. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT
            MAY RESULT FROM YOUR ACCESS TO, USE OR MISUSE OF, OR INABILITY TO
            USE THE PLATFORM, YOUR DEALINGS WITH ANY MEDICAL PRACTITIONER OR
            OTHER USER, AND ANY TWIN MATERIALS OR ALL CONTENT AVAILABLE THROUGH
            THE PLATFORM. YOU UNDERSTAND AND AGREE THAT YOU USE THE PLATFORM,
            AND ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN TWIN MATERIALS OR CONTENT
            THROUGH THE PLATFORM AND ANY ASSOCIATED SITES OR PATFORM, AT YOUR
            OWN DISCRETION AND RISK, AND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY
            DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE
            DEVICE USED IN CONNECTION WITH THE PLATFORM), OR THE LOSS OF DATA
            THAT RESULTS FROM THE USE OF THE PLATFORM OR THE DOWNLOAD OR USE OF
            THAT MATERIAL OR CONTENT. SOME JURISDICTIONS MAY PROHIBIT A
            DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY
            FROM JURISDICTION TO JURISDICTION. TO THE EXTENT THAT WE MAY NOT
            DISCLAIM ANY IMPLIED WARRANTY, THE SCOPE AND DURATION OF SUCH
            WARRANTY WILL BE THE MINIMUM PERMITTED BY LAW.
          </p>
          <h5>17. Limitation of Liability.</h5>
          <p>
            IN NO EVENT WILL TWIN BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR
            LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT
            OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO
            ACCESS OR USE, THE PLATFORM OR ANY TWIN MATERIALS OR CONTENT ON THE
            PLATFORM, INCLUDING BUT NOT LIMITED TO ANY MEDICAL ADVICE OR
            TREATMENT BY THE MEDICAL PRACTITONER, WHETHER BASED ON WARRANTY,
            CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL
            THEORY, AND WHETHER OR NOT TWIN HAS BEEN INFORMED OF THE POSSIBILITY
            OF DAMAGE. ABSENT OUR GROSS NEGLIGENCE OR WILLFUL MISCONDUCT, YOU
            AGREE THAT THE AGGREGATE LIABILITY OF TWIN TO YOU, FOR ALL CLAIMS
            ARISING OUT OF, OR RELATING TO, THE USE OF OR ANY INABILITY TO USE,
            ANY PORTION OF THE PLATFORM OR OTHERWISE UNDER THESE TERMS, WHETHER
            IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (A)
            THE AMOUNT TWIN HAS RECEIVED FOR YOUR ACCESS TO AND USE OF THE
            PLATFORM IN THE TWELVE MONTHS PRIOR TO THE CLAIM; OR (B) TEN
            THOUSAND RUPEES (Rs 10,000). TWIN SHALL IN NO EVENT BE LIABLE TO YOU
            (OR TO ANY THIRD PARTY CLAIMING UNDER OR THROUGH THE YOU) FOR ANY
            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES
            ARISING FROM YOUR USE OF, OR INABILITY TO USE THE PLATFORM. THESE
            EXCLUSIONS APPLY TO ANY CLAIMS FOR LOST PROFITS, LOST DATA, LOSS OF
            GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR ANY
            OTHER COMMERCIAL DAMAGES OR LOSSES, EVEN IF TWIN KNEW OR SHOULD HAVE
            KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, BECAUSE SOME STATES OR
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
            JURISDICTIONS, OUR LIABILITY SHALL BE LIMITED TO THE EXTENT
            PERMITTED BY LAW EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A
            LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF
            DAMAGES IS TO ALLOCATE THE RISKS UNDER THESE TERMS BETWEEN YOU AND
            TWIN. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE
            BARGAIN BETWEEN US. EACH OF THESE PROVISIONS IS SEVERABLE AND
            INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS
            IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS
            ESSENTIAL PURPOSE.
          </p>
          <h5>18. Disagreements between Users.</h5>
          <p>
            You alone are responsible for your communications, interactions,
            agreements, representations, promises or any other involvement with
            other Users. Twin reserves the right, but has no obligation, to
            monitor disagreements between you and other Users. If you have a
            dispute with one or more Users, you irrevocably and forever release
            Twin from claims, demands and damages (actual and consequential) of
            every kind and nature, known and unknown, arising out of or in any
            way connected with such disputes.
          </p>
          <h5>19. Insurance Services</h5>
          <p>
            Twin is not registered with the Insurance Regulatory and Development
            Authority of India (IRDAI) to carry on the business of general
            insurance and is not in the insurance business. However, your
            Medical Practitioner and/or a the organization your Medical
            Practitioner is affiliated to may be registered with IRDAI to carry
            on the business of general insurance and would be required to comply
            with the provisions of the Insurance Act 1938, Insurance Regulatory
            and Development Act, 1999 and Insurance Amendment Act, 2015 and
            rules, regulations and any other order and circular issued
            thereunder and any other applicable laws of India and therefore a
            Medical Practitioner or the entity your Medical Practitioner is
            affiliated to and/or Twin may be subject to certain regulatory
            requirements. Please check with your Medical Practitioner as to
            usage and disclosure of information. Twin may be a “business
            associate” of the Medical Practitioner, and as a result, as and to
            the extent Twin is in fact a “business associate” of Medical
            Practitioners, Twin’s use and disclosure of your information will be
            limited as and to the extent permitted by applicable law and
            regulations, which may include, for example, providing you with
            certain communications via emails, text messages or in app messages
            that contain information, such as appointment reminders,
            encouragement, and advice. If you would prefer not to exchange
            information via email or text message, please notify us at
            PrivacyIndia@TwinHealth.com. You may also request we update,
            correct, or delete your information by contacting us at
            PrivacyIndia@TwinHealth.com; provided however, that we may retain
            any information that we are required to maintain in accordance with
            applicable law and regulations. We regularly review our compliance
            with our Privacy Policy. When we receive formal written complaints,
            we will contact the person who made the complaint to follow up. We
            work with the appropriate regulatory authorities, including local
            data protection authorities, to resolve any complaints regarding the
            transfer of personal data that we cannot resolve with our users
            directly.
          </p>
          <h5>20. Forums and Interactive Services:</h5>
          <p>
            There may be interactive services within the Platform such as blogs,
            chat rooms, forums, instant messaging etc., and other service member
            zones that allow them to input their data, details, materials,
            comments or other similar communications. Using such services on the
            Platform, the User should realize that they are open for wide access
            and review, and any personal data that he/she published or provided
            during registration may become viewable by third parties. Twin is
            not responsible for the security of the User’s information that is
            passed by the User to third parties or provided during use of such
            services. Materials that will be placed by the User in such areas of
            the Platform will be stored in systems/devices of Twin as per Twin’s
            policy, if otherwise not directly documented in each particular
            case.
          </p>
          <p>
            <h5>21. Governing Law.</h5> These Terms are governed by the laws of
            the India without regard to conflict of law principles. If a lawsuit
            or court proceeding is permitted under these Terms, you and Twin
            agree to submit to the personal and exclusive jurisdiction of courts
            in Chennai for the purpose of litigating any dispute. We also agree
            that Twin may seek interim equitable relief in any court having
            jurisdiction. We deliver the Service from our offices in India, and
            we make no representation that Twin Materials included in the
            Service are appropriate or available for use in other locations.
          </p>
          <p>
            <h5>22. General.</h5> These Terms and any Additional Agreements are
            the entire and exclusive understanding and agreement between you and
            Twin regarding your use of the Platform. You may not assign or
            transfer these Terms or your rights under these Terms, in whole or
            in part, by operation of law or otherwise, without our prior written
            consent. We may assign these Terms at any time without notice or
            consent. The failure to require performance of any provision will
            not affect our right to require performance at any other time after
            that, nor will a waiver by us of any breach or default of these
            Terms, or any provision of these Terms, be a waiver of any
            subsequent breach or default or a waiver of the provision itself.
            Use of section headers in these Terms is for convenience only and
            will not have any impact on the interpretation of any provision. If
            any part of these Terms is held to be invalid or unenforceable, the
            unenforceable part will be given effect to the greatest extent
            possible, and the remaining parts will remain in full force and
            effect. Upon termination of these Terms, any provision that by its
            nature or express terms should survive, will survive.
          </p>
          <h5>23. Dispute Resolution and Arbitration.</h5>
          <p>
            23.1 Generally. In the interest of resolving disputes between you
            and Twin in the most expedient and cost-effective manner, you and
            Twin agree that every dispute arising in connection with these Terms
            (including those involving others) will be resolved by binding
            arbitration, except as noted below. Our agreement to arbitrate
            disputes includes all claims arising out of or relating to any
            aspect of these Terms, whether based in contract, tort, statute,
            fraud, misrepresentation, or any other legal theory, and regardless
            of whether a claim arises during or after the termination of these
            Terms.
          </p>
          <p>
            23.2 Exceptions., we both agree that nothing in these Terms will be
            deemed to waive, preclude, or otherwise limit the right of either of
            us to: (a) bring an individual action under the Consumer Protection
            Act prior to demanding arbitration as set forth below; (b) timely
            pursue an enforcement action through the applicable federal, state,
            or local agency if that action is available; (c) seek injunctive
            relief in a court of law at any time; or (d) to file suit in a court
            of law to address an intellectual property infringement claim at any
            time.
          </p>
          <p>
            23.3 Arbitrator. Any arbitration between you and Twin will be
            governed by Arbitration and Conciliation Act, 1996.
          </p>
          <p>
            23.4 Notice. Anyone who intends to seek arbitration must first send
            a written notice of the dispute to the other, by certified mail or
            Federal Express (signature required), or if we do not have a
            physical address on file for you, by electronic mail (“Notice”). The
            address for Notice to Twin is: Twins Digital Services India Private
            Limited, E Block, 9th Floor, IITM Research Part, Kanagam Road,
            Taramani, Chennai, Tamilnadu, India- 600 113..
          </p>
          <p>
            23.5 Demand. The Notice must: (a) describe the nature and basis of
            the claim or dispute; and (b) set forth the specific relief sought
            (“Demand”). We each agree to use good faith efforts to resolve the
            claim directly, but if we do not reach an agreement to do so within
            30 days after the Notice is received, you or Twin may commence an
            arbitration proceeding. The litigants will have the ability to take
            discovery on and exchange relevant information, on a confidential
            basis, about the dispute. During the arbitration, the amount of any
            settlement offer made by you or Twin must not be disclosed to the
            arbitrator until after the arbitrator makes a final, decision and
            award, if any. Except as required to comply with law or other
            obligations, the arbitration proceedings, filings and outcome shall
            be confidential.
          </p>
          <p>
            23.6 Fees. Any arbitration hearing will take place at a Chennai. If
            the arbitrator finds that either the substance of your claim or the
            relief sought in the Demand is frivolous or brought for an improper
            purpose, you agree to reimburse Twin for all monies previously
            disbursed by Twin.
          </p>
          <p>
            23.7 Modifications. If Twin makes any future change to this
            arbitration provision (other than a change to Twin’s address for
            Notice), you may reject the change by sending us written notice
            within 30 days of the change to Twin’s address for Notice, in which
            case your account with Twin will be immediately terminated and this
            arbitration provision, as in effect immediately prior to the
            amendments you reject will survive.
          </p>
          <p>
            23.8 Enforceability. If any portion of Section 23 is found to be
            unenforceable, then Twin can elect to construe the entirety of this
            Section 23 to be null and void and, but in any event, we each agree
            that the exclusive jurisdiction and venue provisions will govern any
            action arising out of or related to these Terms.
          </p>
          <p>
            <h5>24. Consent to Electronic Communications.</h5> By using the
            Platform, you consent to receiving certain electronic communications
            from us as further described in our Privacy Policy. Please read our
            Privacy Policy to learn more about your choices regarding our
            electronic communications practices. You agree that any notices,
            agreements, disclosures, or other communications that we send to you
            electronically will satisfy any legal communication requirements,
            including that those communications be in writing.
          </p>
          <p>
            <h5>25. Contact Information.</h5> The Service is offered by Twins
            Digital Services India Private Limited. You may contact us by
            sending correspondence to that address or by emailing us at
            care@twinhealth.com.
          </p>
          <p>
            26. Individual patient's results may vary depending on the years of
            diabetes, health condition of the patient, overall program adherence
            among many other factors.
          </p>
          <p>
            27. Twin doesn't promote its Whole-Body Digital Twin (“WBDT”)
            technology as a cure to Diabetes. Nor does it claim permanent
            healing or making someone Non-Diabetic. Twin helps its members
            reverse Diabetes completely and live an energetic, happy life full
            of vitality.
          </p>
          <p>
            28. The Health Counsellor consultation calls will be recorded for
            our internal training and quality purposes Service notice: The
            healthcare provider will make reasonable efforts to provide advance
            notice to the patient or their representative of any scheduled
            service interruption, including but not limited to maintenance,
            repairs, or upgrades to equipment or systems. In the event of an
            unforeseen service interruption, the healthcare provider will make
            every effort to restore service as soon as possible and will provide
            notice to the patient or their representative as soon as practical.
            The healthcare provider shall not be liable for any damages or
            losses resulting from any service interruption, except to the extent
            such interruption is due to the provider's willful misconduct or
            gross negligence. This clause outlines the healthcare provider's
            responsibility to provide notice to patients or their
            representatives in the event of scheduled or unscheduled service
            interruptions, as well as the provider's limitations on liability
            for damages resulting from such interruptions.
          </p>
          <h3 className="text-center mt-3">5. Annexures</h3>
          <p>
            1. Once you enroll, you will receive a set of sensors & blood draw
            investigation parameters which you will have to procure on your own.
            This will be given by your care team post your detailed health
            investigations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
